import {useEffect} from "react";
import ServerApi from "../api/ServerApi";
import useFetch from "./useFetch";
import {iCompany} from "../api/intrefaces";

export const useCompany = (id: number) => {

  const {data: company, error, loading, fetch} = useFetch<iCompany>(() =>
    ServerApi.getCompanyByCompanyId(id)
      .then(data => data[0])
  )

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {company, loading, error}
}

