import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap"
import LINK from "../../utils/LINK"
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats"
import StatContractsPlaces from "../../components/Stats/StatContractsPlaces"
import StatContracts from "../../components/Stats/StatContracts"
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext"
import { ENV } from '../../ENV'

const StatsContractsPlacesPage = () => {

  return (
    <PageWrap
      title="Místa realizace zakázek"
      breadcrumbs_items={[
        { label: "Analýzy", link: LINK.stats },
        { label: "Místa realizace zakázek" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.statsPlaces}>
        {!ENV.NEN && <FilterBoxStats selectCompanies/>}
        <StatContractsPlaces/>
        <StatContracts isContractPlaces/>
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StatsContractsPlacesPage
