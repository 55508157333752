import React from "react"
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import FilterBoxStats from "../../components/Filters/Box/FilterBoxStats";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext";
import { ENV } from '../../ENV'
import FilterOrder from "../../components/Filters/FilterOrder";
import Row from "react-bootstrap/Row";
import Paginator from "../../components/Paginator/Paginator";
import WinnersListOfferPrice from "../../components/WinnersList/WinnersListOfferPrice";
import WinnersListSupValue from "../../components/WinnersList/WinnersListSupValue";

const StatsContractsWinnersPage = (props: any) => {
  const isLocationPathnameOfferPrice = props.location.pathname === "/analyzy/prehled-viteznych-uchazecu-nabidkova-cena"

  return (
    <PageWrap
        title={ isLocationPathnameOfferPrice ?
            "Přehled vítězných uchazečů a rozdíl mezi nabídkovou a smluvní cenou"
          : "Přehled vítězných uchazečů a rozdíl mezi předpokládanou a smluvní cenou"
        }
        breadcrumbs_items={[
          {label: "Analýzy", link: LINK.stats},
          {label: isLocationPathnameOfferPrice ?
            "Přehled vítězných uchazečů a rozdíl mezi nabídkovou a smluvní cenou"
          : "Přehled vítězných uchazečů a rozdíl mezi předpokládanou a smluvní cenou"},
        ]}>
      <FilterContextProvider
        name={isLocationPathnameOfferPrice ? PAGE_TYPES.statsWinners : PAGE_TYPES.statsWinnersSupValue}
      >
        {!ENV.NEN && <FilterBoxStats selectCompanies/>}
        <Row className="mb-2 justify-content-end flex-order">
          <FilterOrder maximWidth={320}
             orderOptions={[
               {label: "Název uchazeče (A-Z)", value: "name ASC"},
               {label: "Název uchazeče (Z-A)", value: "name DESC"},
               {label: "IČ - vzestupně", value: "ic ASC"},
               {label: "IČ - sestupně", value: "ic DESC"},
               {label: "Název zakázky (A-Z)", value: "contract_name ASC"},
               {label: "Název zakázky (Z-A)", value: "contract_name DESC"},
               {label: "Číslo zakázky - vzestupně", value: "system_number ASC"},
               {label: "Číslo zakázky - sestupně", value: "system_number DESC"},
               isLocationPathnameOfferPrice ?
               {label: "Nabídková cena v Kč bez DPH - vzestupně", value: "offer_price_wovat ASC"}
               : {label: "Předpokládaná cena v Kč bez DPH - vzestupně", value: "sup_value ASC"},
               isLocationPathnameOfferPrice ?
               {label: "Nabídková cena v Kč bez DPH - sestupně", value: "offer_price_wovat DESC"}
               : {label: "Předpokládaná cena v Kč bez DPH - sestupně", value: "sup_value DESC"},
               {label: "Smluvní cena v Kč bez DPH - vzestupně", value: "contract_wovat_value ASC"},
               {label: "Smluvní cena v Kč bez DPH - sestupně", value: "contract_wovat_value DESC"},
               {label: "Rozdíl v Kč - vzestupně", value: isLocationPathnameOfferPrice ? "diff_offer_price ASC" : "diff_sup_value ASC"},
               {label: "Rozdíl v Kč - sestupně", value: isLocationPathnameOfferPrice ? "diff_offer_price DESC" : "diff_sup_value DESC"},
               {label: "Rozdíl v % - vzestupně", value: isLocationPathnameOfferPrice ? "diff_offer_price_perc ASC" : "diff_sup_value_perc ASC"},
               {label: "Rozdíl v % - sestupně", value: isLocationPathnameOfferPrice ? "diff_offer_price_perc DESC" : "diff_sup_value_perc DESC"},
             ]}
          />
        </Row>
        {isLocationPathnameOfferPrice ? <WinnersListOfferPrice/> : <WinnersListSupValue />}
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
}

export default StatsContractsWinnersPage;