import React, { useEffect } from "react";
import AlertError from "../Alerts/AlertError";
import Loading from "../Loading/Loading";
import AlertInfo from "../Alerts/AlertInfo";
import TableBox from "../ContentBox/TableBox";
import {iWinnerSupValue} from "../../api/intrefaces";
import { NavLink } from "react-router-dom";
import LINK from "../../utils/LINK";
import {formatNumInt, formatPrice} from "../../utils/utils";
import { ENV } from '../../ENV'
import {useFilterWinnersSupValue} from "../../hooks/useFilterWinnersSupValue";

const WinnersListSupValue = () => {
  const { winners, error, loading, setInitFetch } = useFilterWinnersSupValue()

  useEffect(() => {
    setInitFetch();
  }, [setInitFetch]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <AlertError msg={error} />;
  }
  if (!winners || winners.length === 0) {
    return <AlertInfo msg="Žádné zakázky" />;
  }

  return (
    <>
      <TableBox>
        <thead>
          <tr>
            <th>Název uchazeče</th>
            <th>IČ</th>
            <th>Název zakázky</th>
            <th>Číslo zakázky</th>
            <th className="text-end">Předpokládaná cena v Kč bez DPH</th>
            <th className="text-end">Smluvní cena v Kč bez DPH</th>
            <th className="text-end">Rozdíl v Kč</th>
            <th className="text-end">Rozdíl v %</th>
          </tr>
        </thead>
        <tbody>
          {winners.map((winner: iWinnerSupValue, index: number) => (
            <tr key={index}>
              <td>
                {!ENV.NEN ?
                  <NavLink to={`${LINK.contractorDetail}/${winner.id_company}`}>
                    {winner.name}
                  </NavLink>
                : winner.name}
              </td>
              <td>{winner.ic}</td>
              <td>
                <NavLink to={`${LINK.contractDetail}/${winner.id_contract}`}>
                  {winner.contract_name}
                </NavLink>
              </td>
              <td>{winner.system_number}</td>
              <td className="text-end">{formatNumInt(winner.sup_value)}
              </td>
              <td className="text-end">{formatNumInt(winner.contract_wovat_value)}
              </td>
              <td className="text-end">{formatNumInt(winner.diff_sup_value)}
              </td>
              <td className="text-end">{formatPrice(winner.diff_sup_value_perc)}
              </td>
            </tr>
          ))}
        </tbody>
      </TableBox>
    </>
  );
};

export default WinnersListSupValue;
