import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AlertError from "../Alerts/AlertError";
import moment from "moment";
import ContentBox from "../ContentBox/ContentBox";
import { DATE_FORMAT_DAY } from "../../utils/utils";
import { useContractDetail } from "../../hooks/useContractDetail";
import Loading from "../Loading/Loading";
import BoxLine from "../BoxLine/BoxLine";
import { ENV } from '../../ENV'

interface iContractBox {
  id: number | string
}

const ContractBoxDetail = ({ id }: iContractBox) => {
  const { contract, loading, error } = useContractDetail(id)

  const formatAddress = (address: string): any[] => {
    const lines = address.split("\n");
    return lines.map((line, index) => {
      if (index === lines.length - 1) {
        return line;
      } else {
        return <span>{line.endsWith(",") ? line : line + ","}<br/></span>;
      }
    });
  }

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }

  if (!contract) {
    return <AlertError msg="Chyba načtení detailu zakázky"/>
  }

  return (
    <Row>
      <Col md={7}>
        <ContentBox title="Další údaje o zakázce">
          <BoxLine title="ID profilu VVZ">
            {contract.company_vvz_profile_id}
          </BoxLine>
          <BoxLine title="Popis zakázky">
            {contract.contract_description}
          </BoxLine>
          <BoxLine title="Místo plnění">{contract.contract_place}</BoxLine>
          <BoxLine title="CPV kódy">
            {contract.contract_cpv_codes}
            {/*{contract.contract_cpv_codes?.map((kod, index: number) =>*/}
            {/*  <span key={index}>*/}
            {/*    {kod}*/}
            {/*  </span>*/}
            {/*)}*/}
          </BoxLine>
          {contract.date_qualifications && <BoxLine title="Doručení žádosti do">
            {moment(contract.date_qualifications).format(DATE_FORMAT_DAY)}
          </BoxLine>}
          {contract.date_offers && <BoxLine title="Podání nabídek do">
            {moment(contract.date_offers).format(DATE_FORMAT_DAY)}
          </BoxLine>}
          {!ENV.NEN && <BoxLine title="Zdrojový systém">
            <a href={`${ENV.IEN_URL}/contract_display_${id}.html`} target="_blank" rel="noreferrer">
              Detail zakázky v IEN
            </a>
          </BoxLine>}
        </ContentBox>
      </Col>

      <Col md={5}>
        <ContentBox title="Další údaje o organizaci zadavatele">
          <BoxLine small title="IČO">
            {contract.company_ic}
          </BoxLine>
          <BoxLine small title="Adresa">
            <p>{formatAddress(contract.company_adress)}</p>
          </BoxLine>
        </ContentBox>
      </Col>
    </Row>
  );
}

export default ContractBoxDetail;