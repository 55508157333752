import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ENV } from '../../../ENV'
import { ENUM_ID } from '../../../utils/OPTIONS'
import ContentBox from '../../ContentBox/ContentBox'
import FilterDateStart from '../Date/FilterDateStart'
import FilterBtns from '../FilterBtns'
import FilterSearchCompany from '../Search/FilterSearchCompany'
import FilterSearchCountry from '../Search/FilterSearchCountry'
import FilterSearchCpv from '../Search/FilterSearchCpv'
import FilterSelectCompanies from '../Select/FilterSelectCompanies'
import FilterSelectDepartments from '../Select/FilterSelectDepartments'
import FilterSelectEnums from '../Select/FilterSelectEnums'

const FilterBoxContractors = () => {

  return (
    <ContentBox>
      <Row>
        <div className="box_title_red">Zakázka</div>
        <Col sm={6}>
          <FilterSelectCompanies/>
          <FilterSelectEnums
            title="Druh VZ"
            name="otypeCodes"
            enumId={ENUM_ID.otype}
          />

        </Col>
        <Col sm={6}>
          <FilterSelectDepartments/>
          {!ENV.NEN && <FilterDateStart/>}
        </Col>

        <div className="box_title_red">Dodavatel</div>
        <Col sm={6}>
          <FilterSearchCompany/>
          <FilterSearchCpv/>
        </Col>
        <Col sm={6}>
          <FilterSearchCountry/>
        </Col>
      </Row>

      <Row>
        <Col>
          <FilterBtns/>
        </Col>
      </Row>
    </ContentBox>
  )
}

export default FilterBoxContractors
