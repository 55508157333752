import React from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import ContractBox from "../../components/ContractBox/ContractBox";
import {useParams} from "react-router-dom";
import ContractBoxDetail from "../../components/ContractBox/ContractBoxDetail";
import EventsListContract from "../../components/EventsListContract/EventsListContract";
import DocumentsList from "../../components/DocumentsList/DocumentsList";
import HistoryList from "../../components/HistoryList/HistoryList";
import ContentBox from "../../components/ContentBox/ContentBox";
import { ENV } from '../../ENV'
import {PAGE_TYPES} from "../../context/enumPageTypes";
import {FilterContextProvider} from "../../context/FilterContext";
import ContendersList from "../../components/ContendersList/ContendersList";

const ContractDetailPage = () => {

  const {id} = useParams<{ id: string }>()

  return (
    <PageWrap title="Detail zakázky"
              breadcrumbs_items={[
                {label: "Přehled zakázek", link: LINK.contractsList},
                {label: "Detail zakázky"}
              ]}>
      <FilterContextProvider name={PAGE_TYPES.contractDetail}>
        <ContractBox id={id}/>
        <ContractBoxDetail id={id}/>
        <ContentBox title="Uchazeči" collapse>
          <ContendersList id={id}/>
        </ContentBox>
        {!ENV.NEN && <ContentBox title="Události" collapse>
          <EventsListContract id={id}/>
        </ContentBox>}
        <ContentBox title="Dokumenty" collapse>
          <DocumentsList id={id}/>
        </ContentBox>
        <ContentBox title="Stavy" collapse>
          <HistoryList id={id}/>
        </ContentBox>
      </FilterContextProvider>
    </PageWrap>
  );
}

export default ContractDetailPage;