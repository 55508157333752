import React from "react";
import AlertError from "../Alerts/AlertError";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Loading/Loading";
import ContentBox from "../ContentBox/ContentBox";
import BoxLine from "../BoxLine/BoxLine";
import {useCompanyCpv} from "../../hooks/useCompanyCpv";

interface iContractorCPVBox {
  id: number,
}

const ContractorCpvBox = ({id}: iContractorCPVBox) => {

  const {cpvCodes, loading, error} = useCompanyCpv(id)

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <AlertError msg={error}/>
  }
  // if (!cpvCodes) {
  // return <AlertError msg="Chyba načtení cpv kódů dodavatele"/>
  // }


  return (
    <Row>
      <Col>
        <ContentBox title="Přiřazené CPV-kódy">
          {(cpvCodes && cpvCodes.length) ?
            cpvCodes.map(({code, description}) =>
              <BoxLine small title={code}>
                {description}
              </BoxLine>
            )
            :
            "Žádné CPV kódy"
          }
        </ContentBox>
      </Col>
    </Row>
  );
}

export default ContractorCpvBox;
