import React, { useState } from "react";
import PageWrap from "../../components/PageWrap/PageWrap";
import LINK from "../../utils/LINK";
import { useParams } from "react-router-dom";
import ContractorBox from "../../components/ContractorBox/ContractorBox";
import ContractorCpvBox from "../../components/ContractorBox/ContractorCpvBox";
import ContractsList from "../../components/ContractsList/ContractsList";
import Paginator from "../../components/Paginator/Paginator";
import { Col, FormCheck, FormLabel, Row } from "react-bootstrap";
import FilterSearchContracts from "../../components/Filters/Search/FilterSearchContracts";
import {PAGE_TYPES} from "../../context/enumPageTypes";
import { FilterContextProvider } from "../../context/FilterContext";

const ContractorDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const idN = parseInt(id)
  const [filter, setFilter] = useState({
    contractorId: id,
    onlyWonContracts: false,
  });

  function toggleOnlyWon(e: any) {
    const { checked } = e.target;
    setFilter((prev) => ({
      ...prev,
      onlyWonContracts: checked,
    }));
  }

  return (
    <PageWrap
      title="Detail dodavatele"
      breadcrumbs_items={[
        { label: "Audit", link: LINK.audit },
        { label: "Dodavatelé", link: LINK.contractors },
        { label: "Detail dodavatele" },
      ]}
    >
      <FilterContextProvider name={PAGE_TYPES.contractorDetail}>
        <ContractorBox id={idN}/>
        <ContractorCpvBox id={idN}/>
        <Row className="mb-2 justify-content-between">
          <Col className="d-flex align-items-center" sm={{span: 4}}>
            <FilterSearchContracts/>
          </Col>
          <Col className="justify-content-end d-flex align-items-center">
            <FormLabel className="mb-0 mx-2" htmlFor="onlyWonCheck">
              Jen vysoutěžené VZ
            </FormLabel>
            <FormCheck
              onChange={toggleOnlyWon}
              type="checkbox"
              checked={filter.onlyWonContracts}
              id="onlyWonCheck"
              size={10}
            />
          </Col>
        </Row>

        <ContractsList order={"date_start DESC"} filter={filter}/>
        <Paginator />
      </FilterContextProvider>
    </PageWrap>
  );
};

export default ContractorDetailPage;
