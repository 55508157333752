import React from "react"
import ContentBox from "../../ContentBox/ContentBox"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import FilterBtns from "../FilterBtns"
import FilterSelectCompany from "../Select/FilterSelectCompany";
import FilterSelectDepartments from "../Select/FilterSelectDepartments"
import FilterDateStart from "../Date/FilterDateStart"
import FilterSelectEnumsStatic from "../Select/FilterSelectEnumsStatic";
import contractCodeProceduresGrouped from "./contractProceduresGlobalIndicatorsGrouped.json";
import { ENV } from '../../../ENV'

const FilterBoxGlobalIndicators = () => {
  return (
      <ContentBox>
        <Row>
          <Col sm={6}>
            <FilterSelectCompany/>
            <FilterDateStart/>
          </Col>
          <Col sm={6}>
            <FilterSelectDepartments/>
            {!ENV.NEN && <FilterSelectEnumsStatic
                title="Druh ZŘ"
                name="procedureCodes"
                options={contractCodeProceduresGrouped}
            />}
          </Col>
        </Row>

        <FilterBtns/>
      </ContentBox>
  )
}

export default FilterBoxGlobalIndicators
